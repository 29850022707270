<template>
  <v-container class="bg-class pa-4 pt-12" style="height: 100vh; width: 100vw;">
    <backArrow style="z-index: 0" />
    <v-row class="pl-3 pa-8 pt-5 pb-3" align="center">
      <v-col class="pr-0 py-0" cols="3">
        <v-avatar style="padding-left: 0;filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.15));" size="65">
          <img
            :src="avatarImg"
          >
        </v-avatar>
      </v-col>
      <v-col class="pl-0" cols="9">
        <p class="generic-title mb-0">
          {{ bookmarkTitle }}
        </p>
      </v-col>
    </v-row>
    <timeline
      class="pt-0"
      v-if="storyList.length > 0"
      id="v-step-9"
      :timeline-items="storyList"
      card-class="mb-3"
      :refresh-timeline="() => {}"
      @updateTotalizersFeed="updateTotalizersFeed"
    />
  </v-container>
</template>

<script>
import timeline from "../../components/protecoes/timeline";
import backArrow from '../../components/covid/back-arrow';
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import { mapMutations } from "vuex";

export default {
  name: 'bookmarkScreen',
  components: { timeline, backArrow },
  data() {
    return {
      storyList: [],
      timelineList: [],
      avatarImg: '',
      bookmarkTitle: '',
    }
  },
  methods: {
    ...mapMutations([
      "showmsg",
      "loading",
    ]),
    updateTotalizersFeed(){
      this.$emit('updateTotalizersFeed');
    },
    getMidia(feed) {
      this._socialNetworkService
        .GetMidiaFeed(feed.id)
        .then((_res) => {
          if (_res.data && _res.data.length > 0) {
            feed.medias = _res.data
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    getBookmark() {
      let bookmarks = [];
      if (this.timelineList.length > 0) {
        this.timelineList.forEach(function (elem) {
          let store = {
            id: elem.id,
            origemthumb: elem.thumbnail,
            origem: elem.name ? elem.name : "---",
            titulo: elem.description ? elem.description : "---",
            medias: [],
            descricao: elem.contents,
            typemidia: "",
            linkimage: null,
            linkvideo: null,
            lida: elem.view && elem.view > 0,
            lidaOrigem: elem.view && elem.view > 0,
            exibirtudo: false,
            curtidas: elem.like_count ? elem.like_count : 0,
            like: elem.like && elem.like > 0,
            likeClick: 0,
            likeOrigem: elem.like && elem.like > 0,
            likeId: elem.like && elem.like > 0 ? elem.like : 0,
            bookmark: elem.bookmark && elem.bookmark > 0,
            bookmarkOrigem: elem.bookmark && elem.bookmark > 0,
            bookmarkId:
                elem.bookmark && elem.bookmark > 0 ? elem.bookmark : 0,
            datahora: elem.datahora
          };

          //Corrigi possível erro no vínculo do back
          if (store.curtidas == 0 && store.likeId > 0) {
            store.curtidas = 1;
          }


          bookmarks.push(store);
        });
      }
      if (bookmarks && bookmarks.length > 0) {
        this.storyList = bookmarks
      }
      this.storyList.forEach((feed) => {
        if (feed.typemidia == "") {
          this.getMidia(feed);
        }
      });

    },
  },
  mounted() {
    this.getBookmark()
  },
  beforeMount() {
    let { timelineList, avatarImg, bookmarkTitle } = this.$route.params;

    this.timelineList = timelineList && timelineList;
    this.avatarImg = avatarImg && avatarImg;
    this.bookmarkTitle = bookmarkTitle && bookmarkTitle;
  },
  beforeCreate() {
    this._socialNetworkService = new SocialNetworkService();
  }
}
</script>

<style scoped>
.bg-class {
  background: white;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
}
</style>
